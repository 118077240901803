import React from "react";
import { Link } from "gatsby";

import LinkInternal from "components/LinkInternal";
import Image from "components/Image";

const SectorCard = ({ image, category, link, onSite }) => {
  return (
    <Link to={link} className="group w-full">
      <div>
        <figure className="w-full flex">
          <Image image={image} className="object-cover w-full" />
        </figure>
        <figcaption className="text-everglade-500 relative bg-bobalife transition-all duration-300 group-hover:text-white group-hover:bg-cinnabar-500 py-4 px-8">
          <div className="text-lg font-bold uppercase tracking-wider">
            {category || `Retail`}
          </div>
          <p className="text-white absolute right-4 bottom-4 opacity-0 group-hover:opacity-100 transition-all duration-300">
            {onSite ? "ON SITE NOW" : ""}
          </p>
          <LinkInternal label="Read more" />
        </figcaption>
      </div>
    </Link>
  );
};

export default SectorCard;
