import React from "react";
import { graphql } from "gatsby";

import Layout from "components/commercial/Layout";
import HeroImage from "components/HeroImage";
import NextSection from "components/NextSection";
import SectorCard from "components/SectorCard";

export const query = graphql`
  {
    wp {
      page(id: "/commercial/sectors/", idType: URI) {
        id
        title
        slug
        sectorsPage {
          heroImage {
            ...MediaItem
          }
          heroDescription
          heroLabel
          nextSection {
            number
            heading
            label
            link {
              ... on WP_Page {
                id
                uri
              }
            }
            image {
              ...MediaItem
            }
          }
        }
      }
      sectors(where: { orderby: { field: DATE, order: ASC } }) {
        nodes {
          id
          uri
          title
          featuredImage {
            node {
              ...MediaItem
            }
          }
          singleSector {
            detail {
              projectType
              onSite
            }
          }
        }
      }
    }
  }
`;

const Sectors = ({ data }) => {
  const { title } = data.wp.page;
  const fields = data.wp.page.sectorsPage;
  // const sectors = [1, 2, 3, 4, 5];
  const sectors = data.wp.sectors.nodes;
  return (
    <Layout title={title}>
      <main>
        <div className="relative h-[calc(100vh - 92px)] md:h-[75vh] overflow-y-hidden">
          <h1 className="sr-only">{title}</h1>
          <div className="absolute top-0 left-0 bottom-0 z-0 h-full w-full md:w-7/12 lg:w-[45%] xl:w-7/12 bg-everglade"></div>
          {fields.heroImage && (
            <div className="md:container md:mx-auto pt-20 md:pt-[120px]">
              <HeroImage
                number="2"
                image={fields.heroImage}
                label={fields.heroLabel}
                description={fields.heroDescription}
              />
            </div>
          )}
        </div>
        {/* List of sectors */}
        <div className="md:container md:px-6 xl:px-0 mx-6 md:mx-auto grid gap-6 md:grid-cols-2 md:gap-10 my-8 md:my-32">
          {sectors.map((sector, i) => {
            return (
              <SectorCard
                key={i}
                link={sector.uri}
                category={sector.singleSector.detail.projectType}
                image={sector.featuredImage && sector.featuredImage.node}
                onSite={sector.singleSector.detail.onSite}
              />
            );
          })}
        </div>
        <NextSection
          number={fields.nextSection.number}
          label={fields.nextSection.label}
          heading={fields.nextSection.heading}
          link={fields.nextSection.link.uri}
          image={
            fields.nextSection.image
              ? fields.nextSection.image
              : fields.heroImage
          }
        />
      </main>
    </Layout>
  );
};

export default Sectors;
